@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* Light Theme */
:root {
  --color-primary: #0a96e2;
  --color-primary-dark: #073852;
  --color-primary-light: #a1d5f1;
  --color-secondary: #ff9502;
  --color-accent: #ff9502;
  --color-accent-light: #ffe1b7;
  --color-background: #f7fafc;
  --color-text: #2d3748;
  --font-family: "Roboto", sans-serif;
  --font-size: 16px; /* Example */
  --font-weight: 400; /* Example */
}

/* Dark Theme */
[data-theme="dark"] {
  --color-primary: #3182ce;
  --color-secondary: #d53f8c;
  --color-accent: #dd6b20;
  --color-background: #1a202c;
  --color-text: #e2e8f0;
}

/* Custom Theme 1 */
[data-theme="custom1"] {
  --color-primary: #ff6347;
  --color-secondary: #ffa07a;
  --color-accent: #20b2aa;
  --color-background: #f0e68c;
  --color-text: #556b2f;
}

/* Custom Theme 2 */
[data-theme="custom2"] {
  --color-primary: #6a5acd;
  --color-secondary: #7b68ee;
  --color-accent: #dda0dd;
  --color-background: #f5f5f5;
  --color-text: #483d8b;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: var(--color-primary-dark);
  text-decoration: none;
}

a:hover {
  color: var(--color-accent);
}

/* Ant Design Customizations */
.ant-btn-primary {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  padding: 1rem;
  font-weight: bold !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.ant-btn-dangerous {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  background-color: var(--color-danger-dark);
  border-color: var(--color-danger-dark);
}

.ant-btn-success {
  background-color: var(--color-success);
  border-color: var(--color-success);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  background-color: var(--color-success-dark);
  border-color: var(--color-success-dark);
}

.ant-typography-text,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family) !important;
}
.logo {
  width: 105px;
  height: 65px;
}
.text-white {
  color: white;
}
.text-golden {
  color: var(--color-accent);
}

.text-color {
  color: var(--color-text);
}

.container {
  padding: 10px 50px;
}

.list-bg {
  background-color: var(--color-background);
}

.loader {
  color: var(--color-primary) !important;
  width: 100%;
  height: 100vh;
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ant-spin-text {
  color: var(--color-primary) !important;
}
.ant-spin-dot {
  color: var(--color-primary) !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  top: 220px;
}
.ant-carousel .slick-dots li button {
  background-color: rgba(53, 47, 47, 0.5); /* Adjust color and opacity */
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: var(--color-primary); /* Adjust to your primary color */
}

.card-bg-primary {
  background-color: var(--color-primary-dark) !important;
}
.card-bg-secondary {
  background-color: var(--color-accent-light) !important;
}

.ant-card .ant-card-cover > div {
  display: flex !important;
}

h1 {
  color: var(--color-accent);
}
.comment {
  color: red !important;
}

.highlighted-button {
  background-color: var(--color-accent);
  color: white;
}
.formatted-text {
  font-weight: bold;
  font-size: 15px;
}

li {
  list-style: disc !important;
}

.body {
  background-color: #f8faff;
}
